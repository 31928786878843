export default {
  maxWidth: "1360px",
  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
  size: {
    headerHeight: "75px",
    footerHeight: "64px",
  },
  colors: {
    greenLt: "#00C9BE",
    greenDk: "#1BAC9F",
    blueLt: "#203448",
    blueDk: "#122130",
    orange: "#EFA04E",
    grayLt: "#ccc",
    grayDk: "#333",
    dangerLt: "#FF695B",
    dangerDk: "#E56155",
    successLt: "#3DCC79",
    successDk: "#1DAD59",
    warningLt: "#FFBE4F",
    warningDk: "#E4A434",
    white: "#fff",
    black: "#000",
  },
  mixins: {
    visuallyHidden:
      "position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0;",
    zeroList: "list-style: none; margin: 0; padding-left: 0",
    shadow:
      "box-shadow: 0 10px 20px rgba(0,0,0,0.2), 0 6px 6px rgba(0,0,0,0.15);",
  },
  fontSize: {
    h1: "56px",
    h2: "36px",
    h3: "24px",
    h4: "18px",
    h5: "14px",
    text: "16px",
    textSm: "14px",
  },
  zIndex: {
    0: 0,
    1: 100,
    2: 200,
    3: 300,
    4: 400,
    5: 500,
  },
}
