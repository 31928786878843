import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

import theme from "./theme"

export default createGlobalStyle`
  ${reset}
  
  html {
    height: 100%;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Poppins', sans-serif;
    color: ${theme.colors.grayLt};
    background-color: ${theme.colors.blueDk};
    overflow-x: hidden;
    height: 100%
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: ${theme.colors.white};
    line-height: 1.3;
  }
  h1 {
    font-size: ${theme.fontSize.h2};
    line-height: 1.25;
    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSize.h1};
    }
  }
  h2 {
    font-size: ${theme.fontSize.h3};
    @media (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.fontSize.h2};
    }
  }
  h3 {
    font-size: ${theme.fontSize.h4};
    font-weight: 600;
    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSize.h3};
    }
  }
  h4 {
    font-size: ${theme.fontSize.h4};
    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSize.h3};
    }
  }
  p {
    font-size: ${theme.fontSize.text};
    line-height: 1.8;
  }
  a {
    color: ${theme.colors.greenLt};
  }

  // React Modal Animation

  .ReactModal__Content {
    opacity: 0;
    transform-origin: 0 0;
    transform: scale(0.8) translate(-50%, -50%) !important;
    transition: all 0.2s ease-in-out;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) !important;
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0.8) translate(-50%, -50%) !important; 
  }
`
