import React from "react"
import styled, { ThemeProvider } from "styled-components"

import GlobalStyles from "../styles/GlobalStyles"
import theme from "../styles/theme"
import Logo from "../images/logo-sm.svg"

const Footer = styled.footer`
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.greenDk};
  background-color: ${props => props.theme.colors.blueLt};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 20px 40px;
  }
`
const Layout = ({ location, title, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <main style={{ overflow: "hidden" }}>{children}</main>
      <Footer>
        <Logo width={50} height={50} />
        <span>© Integral Stack {new Date().getFullYear()}</span>
      </Footer>
      <GlobalStyles />
    </ThemeProvider>
  )
}

export default Layout
